import { Atom } from ":mods";
export function HeaderSection() {
  return (
    <section class="flex flex-col justify-center items-center bg#paper">
      <div class="w-845px mt-80px">
        <Atom.Microsite.Title bold>Contact Us</Atom.Microsite.Title>
        <p class="text-16px mt-10px">
          Please do not hesitate to get in touch with us if you have any questions about the programme.
        </p>
      </div>
    </section>
  );
}
